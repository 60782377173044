import { Box, Chip, Divider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { host } from "../../../api/apihost";
import fetchApi from "../../../api/fetchApi";
import { useQueryProduct } from "../../../api/queries";
import Button from "../../../components/button/customButton";
import Dialog from "../../../components/dialog/Dialog";
import CustomInput from "../../../components/inputs/cutsomInput";
import KeyValueText from "../../../components/keyValueText/keyValueText";
import CardStat from "../../../components/layout/cardStat/cardStat";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import SectionLayout from "../../../components/layout/sectionLayout";
import useAuth from "../../../hooks/useAuth";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";
import { sumProperty } from "../../../utils/sumProperty";
import { RecentFarmChart } from "./recentFarmChart";

const Inventory = () => {
  const { data } = useQueryProduct();
  const [open, setOpen] = useState(false);
  const [openNewItem, setOpenNewItem] = useState(false);
  const [isSaving, setisSaving] = useState(false);
  const { handleSubmit, control, reset, register } = useForm();
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const [image, setImage] = useState<any>();
  const [currentData, setCurrentData] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseNewItem = () => {
    setCurrentData(null);
    setOpenNewItem(false);
  };

  const createProduct = async (data: any) => {
    setisSaving(true);
    const formData = new FormData();

    formData.append("name", data?.name);
    formData.append("amount", Number(data?.price).toString());
    formData.append("quantity", Number(data?.quantity).toString());
    formData.append("size", Number(data?.size).toString());
    formData.append("image", image);

    fetchApi("product", token)
      .upload(formData)
      .then((e) => {
        if (e.error) {
          toast(e.messages.message);
        } else if (e.title === "TypeError") {
          toast(e.message);
        } else {
          handleClose();
          reset();
          toast("Product successfully added", { type: "success" });
          queryClient.invalidateQueries({ queryKey: ["Products"] });
        }
      })
      .catch((err) => toast(err.message, { type: "error" }))
      .finally(() => setisSaving(false));
  };

  const updateProduct = async (data: any) => {
    setisSaving(true);
    fetchApi("product/update", token)
      .post({
        id: currentData?.id,
        quantity: Number(data?.quantity),
      })
      .then((e) => {
        if (e.error) {
          toast(e.messages.message);
        } else if (e.title === "TypeError") {
          toast(e.message);
        } else {
          handleCloseNewItem();
          reset();
          toast("Product successfully updated", { type: "success" });
          queryClient.invalidateQueries({ queryKey: ["Products"] });
        }
      })
      .catch((err) => toast(err.message, { type: "error" }))
      .finally(() => setisSaving(false));
  };

  const columns = [
    { label: "id", property: "id" },
    {
      label: "Image",
      property: "image",
      formatter: (text: string) => (
        <Box
          component="img"
          sx={{
            height: 100,
            width: 100,
            maxHeight: { xs: 100, md: 100 },
            maxWidth: { xs: 100, md: 100 },
          }}
          alt=""
          src={`${host}${text}`}
        />
      ),
    },
    { label: "Item", property: "name" },
    {
      label: "Quantity in Stock",
      property: "quantity",
    },

    { label: "Date", property: "created_at", formatter: formatDate },

    {
      label: "Status",
      property: "interest",
      formatter: (text: string | number) => (
        <Chip
          color="primary"
          label={`In Stock`}
          sx={{
            bgcolor: "#E5F4EE",
            color: "#359444",
          }}
        />
      ),
    },
  ];
  const transactions = data?.data || [];
  const selectedRows = newSelectObjectByProperties(transactions, columns);

  const uploadimg = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      setImage(e.target.files[0]);
    }
  };
  const updateData = (e: any) => {
    setCurrentData(e);
    setOpenNewItem(true);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardStat
          title="Number of Items in Inventory"
          amount={`${sumProperty(transactions, "quantity")}`}
        />
        <CardStat title="Number of Sold Items" amount={"0"} />
        <CardStat title="Number of Items on Loan" amount={"0"} />
      </Box>
      <SectionLayout title="Farms( amount in tens)">
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box sx={{ height: "40vh", width: "100%", alignSelf: "center" }}>
            <RecentFarmChart />
          </Box>
        </Box>
      </SectionLayout>

      <SectionLayout
        title="List of Items Added"
        rightContent={
          <Button onClick={() => setOpen(true)}>Create new product</Button>
        }
      >
        <Datagrid
          columns={columns}
          rows={selectedRows.reverse()}
          customTitleButton="Add New"
          customAction={updateData}
        />
      </SectionLayout>

      <Dialog
        title="Add new product"
        handleClose={handleClose}
        open={open}
        confirm={handleSubmit(createProduct)}
        isStoreActivated={false}
        isConfirming={isSaving}
        id={1}
      >
        <CustomInput
          control={control}
          id="name"
          label="Product Name"
          name="name"
          disabled={isSaving}
          rules={{
            required: "This field is required",
          }}
          autoFocus
        />
        <CustomInput
          control={control}
          id="price"
          label="Price"
          name="price"
          disabled={isSaving}
          rules={{
            required: "This field is required",
          }}
        />
        <CustomInput
          control={control}
          id="size"
          label="Size"
          name="size"
          disabled={isSaving}
          rules={{
            required: "This field is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Please enter a valid number",
            },
          }}
        />
        <CustomInput
          control={control}
          id="quantity"
          label="Quantity"
          name="quantity"
          disabled={isSaving}
          rules={{
            required: "This field is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Please enter a valid number",
            },
          }}
        />
        <input type="file" {...register("file")} onChange={uploadimg} />
      </Dialog>
      <Dialog
        title={`Add new ${currentData?.name}`}
        handleClose={handleCloseNewItem}
        open={openNewItem}
        confirm={handleSubmit(updateProduct)}
        isStoreActivated={false}
        isConfirming={isSaving}
        id={1}
      >
        <Box sx={{ paddingY: 2 }}>
          <KeyValueText title="Batch Number" value={`00${currentData?.id}`} />
          <br />
          <KeyValueText title="Timestamp" value={currentData?.created_at} />
        </Box>

        <Divider />
        <Box sx={{ paddingY: 2 }}>
          <CustomInput
            control={control}
            id="quantity"
            label="Number of Products"
            defaultValue={currentData?.quantity || 0}
            name="quantity"
            disabled={isSaving}
            rules={{
              required: "This field is required",
            }}
            autoFocus
          />
          <CustomInput
            control={control}
            id="price"
            label="Range Low on Stock"
            name="range"
            defaultValue={100}
            disabled={true}
          />
        </Box>
      </Dialog>
    </div>
  );
};

export default Inventory;
