import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useQueryChat } from "../../api/queries";
import groupMessagesByUserId from "../../utils/groupMessagesByUserId";
import { formatDate } from "../../utils/formatDate";
import useMutationAddMessage from "../../api/mutations/useMutationAddMessage";

const ChatPage = () => {
  const { data, isLoading } = useQueryChat();
  const [activeChat, setActiveChat] = useState<number | null>(null);
  const [text, setText] = useState("");
  const [chatData, setChaData] = useState<any[]>([]);

  useEffect(() => {
    setChaData(data?.data || []);
  }, [data]);

  const chats = groupMessagesByUserId(chatData);
  const activeConversation =
    chats?.find((item) => item.user_id === activeChat) || null;

  const { mutate: addMessage, isPending } = useMutationAddMessage();

  const sendMessage = () => {
    if (text.trim() !== "") {
      setChaData([
        ...chatData,
        {
          id: Date.now(),
          user_id: activeChat,
          message: text.trim(),
          type: "recieved",
          created_at: `${new Date()}`,
          updated_at: `${new Date()}`,
          active: true,
        },
      ]);
      addMessage({ message: text, userid: `${activeChat}` });
      setText("");
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#0D109F",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            minWidth: "25%",
            borderRight: 0.5,
            minHeight: "90vh",
            maxHeight: "90vh",
            borderColor: "#EDEDED",
          }}
        >
          {chats.map((item, key) => {
            return (
              <>
                <Box
                  onClick={() => setActiveChat(item.user_id)}
                  sx={{
                    backgroundColor:
                      item.user_id === activeChat ? "#E5F4EE" : "unset",
                    alignItems: "center",
                    paddingY: 2,
                    cursor: "pointer",
                  }}
                  key={key}
                >
                  <Box
                    sx={{
                      px: 5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton sx={{ p: 0 }}>
                      <Avatar alt="item?.name" />
                    </IconButton>
                    <Box>
                      <Typography sx={{ px: 3 }}>
                        {item?.name || item?.phone}
                      </Typography>
                      <Typography
                        sx={{ px: 3, color: "#767676", fontWeight: "400" }}
                      >
                        Online
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </>
            );
          })}
        </Box>
        <Box
          sx={{
            minWidth: "75%",
            padding: 5,
            height: "90vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              p: 2,
              maxHeight: "80vh",
              minWidth: "100%",
            }}
          >
            {activeChat ? (
              <>
                {activeConversation?.messages
                  ?.reverse()
                  .map((item: any, key: number) => {
                    return <Message message={item} />;
                  })}
              </>
            ) : (
              "select a conversation"
            )}
          </Box>
          <Box
            sx={{
              p: 2,
              backgroundColor: "background.default",
              alignSelf: "flex-end",
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  placeholder="Type a message"
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  endIcon={<Send />}
                  onClick={sendMessage}
                  disabled={isPending}
                  sx={{ height: 55 }}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const Message = ({ message }: { message: any }) => {
  const isUser = message.type === "sent";

  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: isUser ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: isUser ? "flex-start" : "flex-end",
          mb: 0.5,
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            maxWidth: "50%",
            p: 1,
            backgroundColor: isUser ? "#E5F4EE" : "#F5F5F5",
          }}
        >
          <Typography variant="body1">{message.message}</Typography>
        </Paper>
      </Box>
      <Typography
        sx={{
          fontWeight: "200",
          fontSize: "10px",
          textAlign: isUser ? "start" : "end",
        }}
      >
        {formatDate(message.created_at)}
      </Typography>
    </Box>
  );
};

export default ChatPage;
