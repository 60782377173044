import { Box, Chip } from "@mui/material";
import { useQueryStats, useQueryTransaction } from "../../../api/queries";
import CardStat from "../../../components/layout/cardStat/cardStat";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import Loading from "../../../components/layout/loading/Loading";
import SectionLayout from "../../../components/layout/sectionLayout";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";

const LoanPaymentList = () => {
  const { data } = useQueryTransaction();
  const { data: dataStats, isLoading } = useQueryStats();
  const columns = [
    { label: "Farm Name", property: "name" },
    { label: "Phone", property: "phonenumber" },
    { label: "Details Transaction", property: "type" },
    { label: "Date", property: "created_at", formatter: formatDate },
    {
      label: "Status",
      property: "status",
      formatter: (text: string | number) => (
        <Chip
          color={
            text === "successful"
              ? "primary"
              : text === "pending"
              ? "info"
              : "error"
          }
          label={`${text}`}
          sx={{
            bgcolor:
              text === "successful"
                ? "#E5F4EE"
                : text === "pending"
                ? "#E9D5D5"
                : "#E9D5D5",
            color:
              text === "successful"
                ? "#359444"
                : text === "pending"
                ? "#9C7955"
                : "#F86868",
          }}
        />
      ),
    },
    { label: "Amount", property: "amount", formatter: formatCurrency }, // Assurez-vous que la propriété "action" existe dans vos objets row
  ];
  const transactions =
    data?.data.filter(
      (item: any) => item.type === "deposit" || item.type === "withdraw"
    ) || [];
  const selectedRows = newSelectObjectByProperties(transactions, columns);

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardStat
          title="Total Income"
          amount={formatCurrency(dataStats?.data?.income || 0)}
        />
        <CardStat
          title="Total Amount Loan "
          amount={formatCurrency(dataStats?.data?.loanPayment || 0)}
        />
      </Box>

      <SectionLayout title="Recents Transactions">
        <Datagrid columns={columns} rows={selectedRows} />
      </SectionLayout>
    </div>
  );
};

export default LoanPaymentList;
