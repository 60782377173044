import { Chip } from "@mui/material";
import { useQueryLoanApplication } from "../../../api/queries";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import Loading from "../../../components/layout/loading/Loading";
import SectionLayout from "../../../components/layout/sectionLayout";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";
import { useNavigate } from "react-router-dom";

const LoanApplicationList = () => {
  const { data: dataLoan, isLoading: isLoadingLoan } =
    useQueryLoanApplication();
  const navigate = useNavigate();

  const columns = [
    { label: "id", property: "id" },
    { label: "Farm Name", property: "name" },
    { label: "Date Applied", property: "updated_at", formatter: formatDate },
    {
      label: "Status",
      property: "kycApproved",
      formatter: (text: string | number) => (
        <Chip
          color={
            text === "approved" ? "primary" : text === "open" ? "info" : "error"
          }
          label={`${text}`}
        />
      ),
    },
  ];

  const gotoDetails = (e: any) => {
    navigate(`/loan/application/${e.id}`);
  };
  const users =
    dataLoan?.data.filter((item: any) => item.kycApproved !== "open") || [];
  const selectedRows = newSelectObjectByProperties(users, columns);

  return (
    <div>
      <Loading isLoading={isLoadingLoan} />

      <SectionLayout title="">
        <Datagrid
          columns={columns}
          rows={selectedRows}
          customTitleButton="View details"
          customAction={gotoDetails}
        />
      </SectionLayout>
    </div>
  );
};

export default LoanApplicationList;
