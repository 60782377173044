import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useMutationAcceptLoanApplication,
  useMutationRejectLoanApplication,
} from "../../api/mutations";
import { useQueryLoanApplicationDetails } from "../../api/queries";
import Card from "../../components/card/card";
import KeyValueText from "../../components/keyValueText/keyValueText";
import Datagrid from "../../components/layout/customTable/Datagrid";
import Loading from "../../components/layout/loading/Loading";
import SectionLayout from "../../components/layout/sectionLayout";
import formatCurrency from "../../utils/formatCurrency";
import { newSelectObjectByProperties } from "../../utils/selectObjectByProperties";

const LoanApplicationDetailsPage = () => {
  const { id } = useParams();

  const {
    mutate: rejectLoan,
    isPending,
    isSuccess,
  } = useMutationRejectLoanApplication();
  const {
    mutate: acceptLoan,
    isPending: isPendingAccept,
    isSuccess: isSuccessAccept,
  } = useMutationAcceptLoanApplication();
  const { data, isLoading } = useQueryLoanApplicationDetails(id);

  const farm = data?.data || null;

  const columns = [
    { label: "id", property: "id" },
    {
      label: "Document name",
      property: "name",
    },
    {
      label: "Size",
      property: "size",
    },
  ];

  const documents = [
    {
      id: farm?.id_card,
      name: "National Id",
      size: "22kb",
    },
    {
      id: farm?.localisation_plan,
      name: "Localisation Plan",
      size: "35kb",
    },
    {
      id: farm?.security_collateral,
      name: "Collateral Document",
      size: "35kb",
    },
  ];
  const record = farm?.record || [];
  const selectedRows = newSelectObjectByProperties(record, columns);
  const interest = (farm?.interest * farm?.amount) / 100 || 0;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess || isSuccessAccept)
      queryClient.invalidateQueries({ queryKey: ["LoanApplicationDetails"] });
    queryClient.invalidateQueries({ queryKey: ["LoanRequest"] });
    queryClient.invalidateQueries({ queryKey: ["Stats"] });
  }, [isSuccess, isSuccessAccept]);

  const onPreview = (e: string) => {
    console.log(e);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
            <Link
              color="inherit"
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Home
            </Link>
            <Link
              color="inherit"
              to="/loans"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Loan Application List
            </Link>
            <Typography color="#359444">{farm?.name}'s Farm</Typography>
          </Breadcrumbs>

          <Divider />
          <Card title="Loan Application Details">
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                padding: 2,
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar alt={farm?.name} src="./assets/ajiIcon.png" />
              </IconButton>

              <Typography sx={{ px: 3 }}>{farm?.name}</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText title="Name" value={farm?.name} isVertical />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText title="Email" value={farm?.email} isVertical />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Phone Number"
                  value={farm?.phone}
                  isVertical
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Address"
                  value={`${farm?.region} ${farm?.state} ${farm?.country}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText title="Role" value="Owner" isVertical />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Due Date"
                  value={`${farm?.region} ${farm?.state} ${farm?.country}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Profit Share"
                  value={<Chip label={`${farm?.interest} %`} color="success" />}
                  isVertical
                />
              </Box>
            </Box>
          </Card>
          <Box sx={{ paddingX: 4 }}>
            <SectionLayout title="Loan Details">
              <Datagrid
                columns={columns}
                rows={documents}
                customTitleButton="Preview document"
                customAction={onPreview}
              />
            </SectionLayout>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 4,
              paddingBottom: 15,
            }}
          >
            <Box sx={{ width: "40%" }}>
              {farm?.status === "open" && (
                <FormControlLabel
                  control={<Checkbox />}
                  label="All documents have been approved and verified"
                />
              )}
            </Box>
            <Box sx={{ width: "45%" }}>
              {farm?.kycApproved === "completed" && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={isPending || isPendingAccept}
                    variant="contained"
                    size={"large"}
                    sx={{ mt: 3, mb: 2, mx: 2 }}
                    onClick={() => acceptLoan({ loanId: farm?.id })}
                  >
                    Approve
                  </Button>

                  <Button
                    type="submit"
                    fullWidth
                    color="error"
                    variant="contained"
                    size={"large"}
                    disabled={isPending || isPendingAccept}
                    onClick={() => rejectLoan({ loanId: farm?.id })}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default LoanApplicationDetailsPage;
