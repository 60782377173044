import { Chip } from "@mui/material";
import { useQueryOrder } from "../../../api/queries";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import Loading from "../../../components/layout/loading/Loading";
import SectionLayout from "../../../components/layout/sectionLayout";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";

const Orders = () => {
  const { data, isLoading } = useQueryOrder();
  const columns = [
    { label: "id", property: "id" },
    { label: "Name of Signee", property: "name" },
    { label: "Date", property: "created_at", formatter: formatDate },
    {
      label: "Amount",
      property: "total_amount",
      formatter: formatCurrency,
    },

    {
      label: "Status",
      property: "status",
      formatter: (text: string | number) => (
        <Chip
          color={text === "completed" ? "primary" : "info"}
          label={`${text === "ordered" ? "Pending Order" : text}`}
          sx={{
            bgcolor: text === "completed" ? "#E5F4EE" : "#E9D5D5",
            color: text === "completed" ? "#359444" : "#F86868",
          }}
        />
      ),
    },
  ];
  const transactions = data?.data || [];
  const selectedRows = newSelectObjectByProperties(transactions, columns);

  return (
    <div>
      <Loading isLoading={isLoading} />
      <SectionLayout title="All Order List">
        <Datagrid columns={columns} rows={selectedRows} showButton />
      </SectionLayout>
    </div>
  );
};

export default Orders;
